import Img, { FluidObject } from "gatsby-image";
import React, { ReactNode } from "react";
import { Link as ScrollLink } from "react-scroll";
import { graphql, useStaticQuery } from "gatsby";

import Layout from "../components/layout";
import { FluidNodeWithName, FluidObjectsMap } from "../types/common";

import SECTION_SLASH from "../img/slash.svg";
import LOGO_1 from "../img/logo-1.svg";
import LOGO_2 from "../img/logo-2.svg";
import LOGO_3 from "../img/logo-3.svg";
import LOGO_5 from "../img/logo-5.svg";
import LOGO_4 from "../img/logo-4.svg";
import LOGO_6 from "../img/logo-6.svg";
import LOGO_8 from "../img/logo-8.svg";
import LOGO_9 from "../img/logo-9.svg";
import LOGO_10 from "../img/logo-10.svg";
import LOGO_11 from "../img/logo-11.svg";
import LOGO_12 from "../img/logo-12.svg";
import LOGO_13 from "../img/logo-13.svg";
import LOGO_14 from "../img/logo-14.svg";
import LOGO_15 from "../img/logo-15.svg";
import LOGO_16 from "../img/logo-16.svg";
import LOGO_17 from "../img/logo-17.svg";

const Intro = ({ img }: { img: FluidObject }) => (
  <section>
    <div className="mt7-l ph3 ph0-l relative">
      <div className="flex">
        <div className="w-40-l"></div>
        <div className="w-60-l dn db-l">
          <Img className="w-100 h-100" fluid={img} />
        </div>
      </div>

      <div className="absolute-l top-0-l mb6 mb0-l">
        <div className="flex">
          <div className="w-10 dn db-l"></div>
          <h1 className="f2 f1-l lh-title w-60-ns w-80-m w-100 pa2 pt5 ph2">
            <span className="bold-highlighted mr2">
              Empowering human cognition
            </span>{" "}
            through design and technology
          </h1>
        </div>

        <div className="flex">
          <div className="w-20 w-25-m db-ns dn ml0-l"></div>
          <p className="lh-copy f3-l f5 mt0 w-60-l w-75-m pa2">
            flow/control is an{" "}
            <span className="highlighted fw5 dark-gray">
              interdisciplinary research &amp; development studio
            </span>{" "}
            focused on solving complex design problems
          </p>
        </div>
      </div>
    </div>

    <div className="nt5 flex-l">
      <div className="w-40-l w-50 h3-l h2 bg-dark-gray mt0-l mt3"></div>
    </div>

    <div className="flex pv5-l pv3 bg-dark-gray">
      <div className="fl w-20 dn db-l"></div>
      <div className="fl w-100 w-60-l">
        <p className="f2-l f4 fw5 lh-copy ph4 ph2-l near-white">
          We specialize in researching, prototyping, and building{" "}
          <span className="bg-yellow dark-gray fw7 ph1">
            data&#8209;driven interfaces
          </span>{" "}
          that support understanding, discovering, experimenting, and high-level
          decision making.
        </p>

        {/*
        <p className="f2-l f4 fw5 lh-copy ph4 ph2-l near-white mt5-l mt3">
          We specialise in{" "}
          <span className="bg-yellow dark-gray fw7 ph1">
            information&#8209;dense tooling
          </span>{" "}
          shaping lasting products, services, and experiences across different
          industries and platforms.
        </p>
          */}
      </div>
      <div className="fl w-20 dn db-l"></div>
    </div>

    <div className="fl w-60-l w-50 h3-l h2 bg-transparent"></div>
    <div className="fl w-40-l w-50 h3-l h2 bg-dark-gray"></div>
  </section>
);

const SectionAnchor = ({ id }: { id: string }) => (
  <a
    id={id}
    style={{
      display: "block",
      position: "relative",
      visibility: "hidden",
      top: -50,
    }}
  />
);

const SectionHeader = ({
  children,
  id,
}: {
  children: ReactNode;
  id: string;
}) => (
  <div className="flex w-100 ph3 ph0-l">
    <SectionAnchor id={id} />

    <div className="fl w-10 dn db-l">
      <div className="h3 flex items-center tr pa2">
        <div className="fw6 link dark-gray w-100">
          <img src={SECTION_SLASH} />
        </div>
      </div>
    </div>
    <div className="fl w-30">
      <div className="h3 flex items-center tl pa2">
        <h3 className="f-subheadline-l f2 fw4 dark-gray w-100">{children}</h3>
      </div>
    </div>
  </div>
);

const FootnoteLink = ({
  target,
  children,
}: {
  target: string;
  children: ReactNode;
}) => (
  <ScrollLink
    to={target}
    className="scroll-link link dark-gray highlighted fw5 dim pointer"
    smooth
    offset={-10}
  >
    {children}
  </ScrollLink>
);

const Mission = ({ img }: { img: FluidObject }) => (
  <section className="mt5 mt6-l">
    <SectionHeader id="mission">Mission</SectionHeader>

    <div className="flex w-100 ph3 ph0-l">
      <div className="w-10 dn db-l"></div>
      <div className="w-100 w-75-m w-50-l">
        <p className="pa2 f3-l f5 lh-copy mt4-l ma0">
          flow/control practice is built on a premise that the{" "}
          <FootnoteLink target="footnote-1">new dynamic medium</FootnoteLink>
          <sup className="our-gray fw6 f5-l f7">1</sup>
          we work with should become a{" "}
          <FootnoteLink target="footnote-2">tool for thinking</FootnoteLink>
          <sup className="our-gray fw6 f5-l f7">2</sup>, augmenting what we, as
          humans, do best instead of replacing us. Our approach often taps into{" "}
          <FootnoteLink target="footnote-3">
            future&#8209;of&#8209;coding research
          </FootnoteLink>
          <sup className="our-gray fw6 f5-l f7">3</sup>, exploring new ways of
          design and computation.
        </p>
      </div>
    </div>

    <div className="w-100 mt5-l mt3 relative">
      <div className="flex w-100">
        <div className="w-30-l"></div>
        <div className="w-70-l w-100">
          <Img className="w-100" fluid={img} />
        </div>
      </div>

      <div className="absolute bottom-0">
        <div className="pb4 flex">
          <div className="fl w-10"></div>
          <h1 className="f1-l f2-ns f3 w-80-l w-90 ph2 lh-title">
            <span className="bold-highlighted">
              We are tool builders, conscious of the fact that the tools we
              build, later shape us.
            </span>
          </h1>
        </div>
      </div>
    </div>

    <div className="flex w-100 ph3 ph0-l">
      <div className="w-10 dn db-l"></div>
      <div className="w-100 w-75-m w-50-l">
        <p className="pa2 f3-l f5 lh-copy mt5 mt3-m">
          Our approach is rooted in{" "}
          <FootnoteLink target="footnote-4">
            user-centered<sup className="our-gray fw6 f5-l f7">4</sup> and
            prototype&#8209;driven design
          </FootnoteLink>
          . Our process is established on{" "}
          <FootnoteLink target="footnote-5">
            rapid, collaborative experiments
          </FootnoteLink>
          <sup className="our-gray fw6 f5-l f7">5</sup> with iteratively
          generated tools, and close collaboration with our clients.
        </p>
        {/*
        <p className="pa2 f3-l f5 lh-copy mt3-l">
          We always aim to generate breakthroughs, challenging boundaries
          between user experience and technological paradigms,{" "}
          <span className="highlighted fw5 dark-gray">
            fostering innovation, creativity, and productivity.
          </span>
        </p>
          */}
      </div>
    </div>

    <div className="flex-l w-100 ph3 ph0-l">
      <div className="w-10 dn db-l"></div>
      <div className="w-50-l w-75-m w-100 pa2-l">
        <ol className="pa2 ph0-l list pl2 mt4 mb5 gray f6-l f7 lh-copy">
          {[
            [
              "Alan Kay, Adele Goldberg",
              "Personal Dynamic Media",
              "http://www.newmediareader.com/book_samples/nmr-26-kay.pdf",
            ],
            [
              "Vannevar Bush",
              "As We May Think",
              "https://www.theatlantic.com/magazine/archive/1945/07/as-we-may-think/303881/",
            ],
            [
              "Ink&Switch",
              "End-User Programming",
              "https://www.inkandswitch.com/end-user-programming.html",
            ],
            [
              "Don Norman",
              "The Design of Everyday Things",
              "https://en.wikipedia.org/wiki/The_Design_of_Everyday_Things",
            ],
            [
              "MIT ThinkCycle",
              "Sharing Distributed Design Knowledge for Open Collaborative Design",
              "https://pdfs.semanticscholar.org/13ea/5a764927c4e0e4409d867a1338a46510d9aa.pdf",
            ],
          ].map(([author, title, url], idx) => (
            <li key={idx}>
              <a
                id={`footnote-${idx + 1}`}
                href={url}
                className="link gray dim"
              >
                {idx + 1} {author} <span className="fw6">{title}</span>
              </a>
            </li>
          ))}
        </ol>
      </div>
    </div>

    <div className="flex w-100 ph3 ph0-l">
      <div className="fl w-10 db-l dn"></div>
      <div className="fl w-80-l w-100 pv2">
        <div className="bb bw3 b--dark-gray" />
      </div>
    </div>
  </section>
);

const Process = ({ img }: { img: FluidObject }) => (
  <section className="mt6-l mt5">
    <SectionHeader id="process">Process</SectionHeader>

    <div className="flex-l w-100 ph3 ph0-l">
      <div className="w-10 dn db-l"></div>
      <div className="w-50-l w-75-m w-100">
        <p className="pa2 f3-l f5 lh-copy mt4-l ma0">
          Our work starts by researching domain-specific knowledge, and
          exploring the legacy environment, creating a{" "}
          <span className="highlighted fw5 dark-gray">
            shared understanding
          </span>{" "}
          among all collaborators.
        </p>
      </div>
    </div>

    <div className="w-100 mt5-l mt3 relative">
      <div className="flex w-100">
        <div className="w-70-l w-100">
          <Img className="w-100" fluid={img} />
        </div>
      </div>

      <div className="absolute bottom-0">
        <div className="pb4 flex">
          <div className="fl w-10"></div>
          <h1 className="f1-l f2-ns f3 w-80-l w-90 ph2 lh-title">
            <span className="bold-highlighted">
              We explore the solution space for a given problem through
              sketching &mdash; both on paper and in code.
            </span>
          </h1>
        </div>
      </div>
    </div>

    {/*
    <div className="flex-l w-100 ph3 ph0-l">
      <div className="w-10 dn db-l"></div>
      <div className="w-50-l w-75-m w-100">
        <p className="pa2 f3-l f5 lh-copy mt5 mt3-m">
          We{" "}
          <span className="highlighted fw5 dark-gray">
            validate every stage of design
          </span>{" "}
          through the end-user perspective. Our practice is based on cognitive
          laws and user research methodologies. We investigate contexts,
          behaviours, decisions, and perceptions, intending to create lasting
          products with positive impact.
        </p>
      </div>
    </div>
      */}

    <div className="flex w-100 mt5-l mt4 ph3 ph0-l">
      <div className="fl w-30-l w-25-m dn db-l db-m"></div>
      <div className="fl w-40-l w-50-m w-100 pa2">
        <h4 className="f5-l f4 light-silver fw6 tracked ma0 pa0 ttu">
          Capabilities
        </h4>
      </div>
    </div>

    <div className="flex w-100 ph3 ph0-l">
      <div className="fl w-30-l w-25-m dn db-l db-m"></div>
      <div className="w-75 w-50-m w-30-l">
        <div className="flex justify-between items-center">
          <div className="f2-l f4 fw6 dark-gray ma0 pa2">Development</div>
          <div className="bg-yellow w-100" style={{ height: 4 }}></div>
        </div>
      </div>
      <div className="w-25 w-20-l tr tl-m tl-l">
        <div className="f2-l f4 fw6 dark-gray ma0 pa2">Design</div>
      </div>
    </div>

    <div className="flex w-100 mt3-l h-9r ph3 ph0-l">
      <div className="w-20 dn db-l"></div>
      <div className="w-10-l w-25 db-l db-m dn">
        <div className="rotate90 our-gray fw6 ttu w-9r tc ml3">Discovery</div>
      </div>
      <div className="w-30-l w-25-m w-50 pa2">
        <ul className="list lh-copy f5-l f6 pl0 mt3">
          <li>Legacy Environment Review</li>
          <li>Prior Art Research</li>
        </ul>
      </div>
      <div className="w-25 dn db-m"></div>
      <div className="w-20-l w-25-m w-50 tr tl-m tl-l pa2">
        <ul className="list lh-copy f5-l f6 pl0 mt3">
          <li>Workshops</li>
          <li>Design Research</li>
        </ul>
      </div>
    </div>

    <div className="flex w-100 h-9r ph3 ph0-l">
      <div className="w-20 dn db-l"></div>
      <div className="w-10-l w-25 db-l db-m dn bg-our-gray">
        <div className="rotate90 light-gray fw6 ttu w-9r tc ml3">Planning</div>
      </div>
      <div className="w-30-l w-25-m w-50 pa2 bg-our-gray">
        <ul className="list lh-copy f5-l f6 pl0 mt3">
          <li>Technical Requirements</li>
        </ul>
      </div>
      <div className="w-25 dn db-m bg-our-gray"></div>
      <div className="w-20-l w-25-m w-50 tr tl-m tl-l pa2 bg-our-gray">
        <ul className="list lh-copy f5-l f6 pl0 mt3">
          <li>Project Definition</li>
          <li>Scope of Work</li>
          <li>Scheduling</li>
        </ul>
      </div>
    </div>

    <div className="flex w-100 h-9r ph3 ph0-l">
      <div className="w-20 dn db-l"></div>
      <div className="w-10-l w-25 db-l db-m dn">
        <div className="rotate90 our-gray fw6 ttu w-9r tc ml3">Prototyping</div>
      </div>
      <div className="w-30-l w-25-m w-50 pa2">
        <ul className="list lh-copy f5-l f6 pl0 mt3">
          <li>Exploratory Design</li>
          <li>Rapid Prototyping</li>
          <li>Data Visualisation</li>
        </ul>
      </div>
      <div className="w-25 dn db-m"></div>
      <div className="w-20-l w-25-m w-50 tr tl-m tl-l pa2">
        <ul className="list lh-copy f5-l f6 pl0 mt3">
          <li>Ideation</li>
          <li>Interactive Sketches</li>
        </ul>
      </div>
    </div>

    <div className="flex w-100 h-9r ph3 ph0-l">
      <div className="w-20 dn db-l"></div>
      <div className="w-10-l w-25 db-l db-m dn bg-our-gray">
        <div className="rotate90 light-gray fw6 ttu w-9r tc ml3">
          Validation
        </div>
      </div>
      <div className="w-30-l w-25-m w-50 pa2 bg-our-gray">
        <ul className="list lh-copy f5-l f6 pl0 mt3">
          <li>Analitics</li>
        </ul>
      </div>
      <div className="w-25 dn db-m bg-our-gray"></div>
      <div className="w-20-l w-25-m w-50 tr tl-m tl-l pa2 bg-our-gray">
        <ul className="list lh-copy f5-l f6 pl0 mt3">
          <li>User Interviews</li>
          <li>Contextual Inquiry</li>
          <li>Longitunidal Study</li>
        </ul>
      </div>
    </div>

    <div className="flex w-100 h-9r ph3 ph0-l">
      <div className="w-20 dn db-l"></div>
      <div className="w-10-l w-25 db-l db-m dn">
        <div className="rotate90 our-gray fw6 ttu w-9r tc ml3">Development</div>
      </div>
      <div className="w-30-l w-25-m w-50 pa2">
        <ul className="list lh-copy f5-l f6 pl0 mt3">
          <li>Fullstack</li>
          <li>Native</li>
          <li>Mobile</li>
          <li>Embedded</li>
        </ul>
      </div>
      <div className="w-25 dn db-m"></div>
      <div className="w-20-l w-25-m w-50 tr tl-m tl-l pa2">
        <ul className="list lh-copy f5-l f6 pl0 mt3">
          <li>UI Design</li>
        </ul>
      </div>
    </div>

    <div className="flex w-100 ph3 ph0-l mt5">
      <div className="fl w-10 db-l dn"></div>
      <div className="fl w-80-l w-100 pv2">
        <div className="bb bw3 b--dark-gray" />
      </div>
    </div>
  </section>
);

const Work = ({ images }: { images: FluidObjectsMap }) => (
  <section className="mt6-l mt5">
    <SectionHeader id="work">Work</SectionHeader>

    <div className="flex w-100 ph3 ph0-l">
      <div className="w-10 dn db-l"></div>
      <div className="w-50-l w-75-m w-100">
        <p className="pa2 f3-l f5 lh-copy mt4-l ma0">
          We work with cutting-edge startups and global businesses, collaborate
          with multiple studios and agencies, solving problems{" "}
          <span className="highlighted fw5 dark-gray">
            from initial concepts to final execution
          </span>
          .
        </p>
      </div>
    </div>

    <div className="flex-l w-100 ph3 ph0-l mt5">
      <div className="w-10 db-l dn"></div>

      <div className="w-40-l w-75-m w-100">
        <div>
          <Img className="w-100" fluid={images["work-causal"]} />
        </div>
        <div className="w-100">
          <p className="ma0 pa2 lh-copy f5-l f6">
            Redesigned functionality and experience for{" "}
            <span className="highlighted fw5 dark-gray">Causal</span> delivering
            versatile, familiar, and powerful modeling and forecasting tool.
          </p>
        </div>
      </div>

      <div className="w-10-l dn db-l"></div>

      <div className="w-30-l w-75-m w-100 mt5 ml-25-m">
        <Img
          className="w-100"
          fluid={[
            { ...images["work-moleculeone"], media: "(min-width: 60em)" },
            images["work-moleculeone-medium"],
          ]}
        />
        <div className="w-100">
          <p className="ma0 pa2 lh-copy f5-l f6">
            Invented a new interface for{" "}
            <span className="highlighted fw5 dark-gray">Molecule.one</span>,
            enabling shorter drug development time in large pharmaceutical
            corporations.
          </p>
        </div>
      </div>
    </div>

    <div className="flex-l w-100 ph3 ph0-l mt5-m">
      <div className="w-10 dn db-l"></div>

      <div className="w-30-l w-75-m w-100 mt5 nt5-l">
        <Img
          className="w-100"
          fluid={[
            { ...images["work-fibaro"], media: "(min-width: 60em)" },
            images["work-fibaro-medium"],
          ]}
        />
        <div className="w-100">
          <p className="ma0 pa2 lh-copy f5-l f6">
            Developed visual and textual domain-specific language for{" "}
            <span className="highlighted fw5 dark-gray">
              automating smart homes
            </span>
            .
          </p>
        </div>
      </div>

      <div className="w-10 dn db-l"></div>

      <div className="w-40-l w-75-m w-100 mt5 ml-25-m">
        <div>
          <Img className="w-100" fluid={images["work-esa"]} />
        </div>
        <div className="w-100">
          <p className="ma0 pa2 lh-copy f5-l f6">
            Designed and implemented layout management system and
            information-dense charts for{" "}
            <span className="highlighted fw5 dark-gray">
              European Space Agency
            </span>
            .
          </p>
        </div>
      </div>
    </div>

    <div className="flex-l w-100 ph3 ph0-l">
      <div className="w-10 dn db-l"></div>

      <div className="w-40-l w-75-m w-100 mt5">
        <div>
          <Img className="w-100" fluid={images["work-hot"]} />
        </div>
        <div className="w-100">
          <p className="ma0 pa2 lh-copy f5-l f6">
            Created tooling for{" "}
            <span className="highlighted fw5 dark-gray">
              Humanitarian OpenStreetMaps
            </span>{" "}
            to help visualize, understand, and share their progress with
            stakeholders.
          </p>
        </div>
      </div>

      <div className="w-10 dn db-l"></div>

      <div className="w-30-l w-75-m w-100 mt6-l mt5 ml-25-m">
        <Img
          className="w-100"
          fluid={[
            { ...images["work-london"], media: "(min-width: 60em)" },
            images["work-london-medium"],
          ]}
        />
        <div className="w-100">
          <p className="ma0 pa2 lh-copy f5-l f6">
            Built comprehensive interface for the{" "}
            <span className="highlighted fw5 dark-gray">Mayor of London</span>,
            showing the next 50 years of London development, to aid high-level
            decision making in the city.
          </p>
        </div>
      </div>
    </div>

    <div className="flex-l w-100 ph3 ph0-l nt5-l">
      <div className="w-10 dn db-l"></div>

      <div className="w-30-l w-75-m w-100 mt5 nt5-l">
        <div>
          <Img
            className="w-100"
            fluid={[
              { ...images["work-google"], media: "(min-width: 60em)" },
              images["work-google-medium"],
            ]}
          />
        </div>
        <div className="w-100">
          <p className="ma0 pa2 lh-copy f5-l f6">
            Designed and implemented a bespoke{" "}
            <span className="highlighted fw5 dark-gray">
              data visualization for Google I/O
            </span>
            , to share knowledge about the global presence of Android devices.
          </p>
        </div>
      </div>
    </div>

    <div className="flex-l w-100 ph3 ph0-l mt4">
      <div className="fl w-10 dn db-l"></div>
      <div className="fl w-50-l w-75-m w-100">
        <p className="pa2 f3-l f5 mt4-l mt3 lh-copy">
          We work{" "}
          <span className="highlighted fw5 dark-gray">
            remotely with clients all over the world
          </span>
          , either as in-house team extension or an independent department.
        </p>
      </div>
    </div>

    <div className="flex w-100 ph3 ph0-l center items-center justify-center pt5">
      <img className="mh3" src={LOGO_1} />
      <img className="mh3 dn db-m db-l" src={LOGO_2} />
      <img className="mh3 dn db-m db-l" src={LOGO_3} />
      <img className="mh3" src={LOGO_5} />
      <img className="mh3 dn db-l" src={LOGO_6} />
    </div>

    <div className="flex w-50-l w-100 ph3 ph0-l center items-center justify-center mt3-l mt4">
      <img className="mh3 dn db-m db-l" src={LOGO_8} />
      <img className="mh3 dn db-m db-l" src={LOGO_9} />
      <img className="mh3" src={LOGO_10} />
      <img className="mh3 db-m db-l" src={LOGO_11} />
      <img className="mh3 dn db-l" src={LOGO_12} />
    </div>

    <div className="flex w-60-l w-100 ph3 ph0-l center items-center justify-center mt3-l mt4">
      <img className="mh3" src={LOGO_13} />
      <img className="mh3" src={LOGO_14} />
      <img className="mh3 dn db-m db-l" src={LOGO_15} />
      <img className="mh3 dn db-m db-l" src={LOGO_16} />
      <img className="mh3 dn db-l" src={LOGO_17} />
    </div>

    <div className="db dn-l flex w-100 ph3 ph0-l mt4">
      <div className="fl w-25"></div>
      <div className="fl w-50 pa2 tc">and many more...</div>
    </div>

    <div className="flex w-100 ph3 ph0-l mt5 mt6-l">
      <div className="fl w-10 db-l dn"></div>
      <div className="fl w-80-l w-100 pv2">
        <div className="bb bw3 b--dark-gray"></div>
      </div>
    </div>
  </section>
);

const About = () => (
  <section className="mt6-l mt5">
    <SectionHeader id="about">About</SectionHeader>

    <div className="flex w-100 ph3 ph0-l">
      <div className="w-10 dn db-l"></div>
      <div className="w-50-l w-75-m w-100">
        <p className="pa2 f3-l f5 lh-copy mt4-l mt0">
          Our studio was founded in 2015 in Poznań, Poland, by a group of
          enthusiasts of data-driven design and creative uses of technology.
        </p>
        <p className="pa2 f3-l f5 lh-copy mt0">
          <span className="highlighted fw5 dark-gray">
            We are a boutique studio, focused on solving problems
          </span>
          , instead of artificial growth.
        </p>
      </div>
    </div>
  </section>
);
type IndexProps = {
  path: string;
};

const Index = ({ path }: IndexProps) => {
  const data = useStaticQuery(graphql`
    query {
      introImage: file(relativePath: { eq: "hero-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      missionImage: file(relativePath: { eq: "hero-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      processImage: file(relativePath: { eq: "hero-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      workImages: allFile(filter: { relativePath: { glob: "work-*.png" } }) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 640) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  const workImages = data.workImages.edges.reduce(
    (memo: FluidObjectsMap, { node }: FluidNodeWithName) => ({
      ...memo,
      [node.childImageSharp.fluid.originalName.replace(".png", "")]: node
        .childImageSharp.fluid,
    }),
    {}
  );

  return (
    <Layout path={path}>
      <Intro img={data.introImage.childImageSharp.fluid} />
      <Mission img={data.missionImage.childImageSharp.fluid} />
      <Process img={data.processImage.childImageSharp.fluid} />
      <Work images={workImages} />
      <About />
    </Layout>
  );
};

export default Index;
