import Headroom from "react-headroom";
import React, { ReactNode } from "react";
import { Link as ScrollLink } from "react-scroll";

import SEO from "./seo";

import "tachyons";
import "./main.css";

import LOGO from "../img/logo.svg";
import LOGO_TIGHT from "../img/logo-tight.svg";

import IMG_TWITTER from "../img/twitter.svg";
import IMG_FACEBOOK from "../img/facebook.svg";
import IMG_LINKEDIN from "../img/linkedin.svg";
import { Link } from "gatsby";

const Background = () => (
  <div
    className="mw9 flex center vh-100 fixed absolute--fill ph3 ph0-l"
    style={{ zIndex: -1 }}
  >
    <div className="w-10-l w-25 vh-100 br bl b--near-white"></div>
    <div className="w-10-l w-25 vh-100 br b--near-white"></div>
    <div className="w-10-l w-25 vh-100 br b--near-white"></div>
    <div className="w-10-l w-25 vh-100 br b--near-white"></div>
    <div className="w-10-l w-25 vh-100 br b--near-white dn db-l"></div>
    <div className="w-10-l w-25 vh-100 br b--near-white dn db-l"></div>
    <div className="w-10-l w-25 vh-100 br b--near-white dn db-l"></div>
    <div className="w-10-l w-25 vh-100 br b--near-white dn db-l"></div>
    <div className="w-10-l w-25 vh-100 br b--near-white dn db-l"></div>
    <div className="w-10-l w-25 vh-100 br b--near-white dn db-l"></div>
  </div>
);

const MenuLink = ({
  target,
  text,
  useScroll,
}: {
  target: string;
  text: string;
  useScroll: boolean;
}) => (
  <div className="h3 flex items-center justify-end pa1">
    {useScroll ? (
      <ScrollLink
        activeClass="highlighted"
        to={target}
        className="section-url scroll-link outline-0 fw6 link dark-gray dim pointer"
        spy
        smooth
      >
        {text}
      </ScrollLink>
    ) : (
      <Link
        className="section-url scroll-link outline-0 fw6 link dark-gray dim pointer"
        to={`/#${target}`}
      >
        {text}
      </Link>
    )}
  </div>
);

type HeaderProps = {
  path: string;
};

const Header = ({ path }: HeaderProps) => {
  const isIndex = path === "/";

  return (
    <Headroom>
      <header className="flex mw9 center h3 w-100 top-0-l bg-white z-5 ph3 ph0-l">
        <div className="w-10-l br bl-l b--near-white"></div>
        <div className="w-10-l w-25 br b--near-white">
          <div className="h3 flex items-center">
            <Link to="/" className="center dim">
              <img src={LOGO} />
            </Link>
          </div>
        </div>
        <div className="w-10-l w-25 br b--near-white"></div>
        <div className="w-10-l w-25 br b--near-white"></div>
        <div className="w-10-l w-25 br b--near-white"></div>
        <div className="w-10-l w-25 br b--near-white dn db-l">
          <MenuLink target="mission" text="Mission" useScroll={isIndex} />
        </div>
        <div className="w-10 br b--near-white dn db-l">
          <MenuLink target="process" text="Process" useScroll={isIndex} />
        </div>
        <div className="w-10 br b--near-white dn db-l">
          <MenuLink target="work" text="Work" useScroll={isIndex} />
        </div>
        <div className="w-10 br b--near-white dn db-l">
          <MenuLink target="about" text="About" useScroll={isIndex} />
        </div>
        <div className="w-10-l br b--near-white dn db-l"></div>
      </header>
    </Headroom>
  );
};

const Footer = () => (
  <footer className="w-100 bg-dark-gray mt6 pt3 pb5">
    <div className="mw9 center">
      <div className="w-100 flex-l ph3 ph0-l">
        <div className="w-10 pa2 dn db-l">
          <div className="pa1 mt5 tr">
            <img src={LOGO_TIGHT} />
          </div>
        </div>
        <div className="w-10 dn db-l"></div>

        <div className="w-100 w-50-l pa2-l">
          <div className="flex items-center tl f2 near-white">
            <h4 className="f2-l f4 mt5 mb3">
              <div className="fw3">Interested in working with us?</div>
              <div className="fw5">
                Say{" "}
                <a className="yellow dim" href="mailto:hi@flow-control.io">
                  hi@flow-control.io
                </a>
              </div>
            </h4>
          </div>
        </div>
      </div>

      <div className="w-100 flex ph3 ph0-l mb5">
        <div className="w-20 dn db-l"></div>
        <div className="w-100 w-25-m w-20-l pa2-l pt3 near-white">
          <p className="f3-l f5 fw5 mb2">Szymon Kaliski</p>
          <p className="f5-l f6 fw3 mt0">Founder / Designer &amp; Developer</p>
          <p className="f5-l f6 fw4">
            <a className="yellow dim" href="mailto:szymon@flow-control.io">
              szymon@flow-control.io
            </a>
          </p>
        </div>
        <div className="w-100 w-25-m w-20-l pa2-l pt3 near-white">
          <p className="f3-l f5 fw5 mb2">Tomasz Gęstwicki</p>
          <p className="f5-l f6 fw3 mt0">Partner / Designer</p>
          <p className="f5-l f6 fw4">
            <a className="yellow dim" href="mailto:tomasz@flow-control.io">
              tomasz@flow-control.io
            </a>
          </p>
        </div>
      </div>

      <div className="w-100 flex-l flex-m ph3 ph0-l mt0 mt5-m mt5-l">
        <div className="w-20 dn db-l"></div>

        <div className="w-100 w-50-m w-30-l pa2-l mb4">
          <div className="fw5 lh-copy mb3">
            <div className="near-white fw3">
              Want to keep up with the latest from flow/control?
            </div>
            <div className="yellow fw4">Subscribe to Szymon's newsletter:</div>
          </div>

          <form
            action="https://buttondown.email/api/emails/embed-subscribe/szymonkaliski"
            className="w-100 w-100-l flex ba b--near-white"
            method="post"
            target="popupwindow"
            onSubmit={() => {
              window.open(
                "https://buttondown.email/szymonkaliski",
                "popupwindow",
                "scrollbars=no,width=800,height=600"
              );
            }}
          >
            <div className="w-80">
              <input
                className="bn bg-dark-gray b--near-white near-white w-100 pa2"
                type="email"
                name="email"
                placeholder="Enter e-mail adress here"
              />
            </div>
            <div
              className="w-20 flex justify-center ma2"
              style={{ borderLeft: "1px solid #eee" }}
            >
              <button className="w-100 bn br bg-dark-gray b--near-white yellow ttu pointer dim f7 fw3 mt1">
                Send
              </button>
            </div>
          </form>
        </div>

        <div className="w-10-l w-25-m dn db-m db-l"></div>

        {/*
        <div className="w-100 w-25-m w-20-l pa2-l">
          <p className="near-white mt0 fw3">
            Or simply <span className="yellow">follow us.</span>
          </p>

          <div className="flex mt3-l mt2">
            <a className="mr2 dim" href="https://twitter.com/flow_control_io">
              <img src={IMG_TWITTER} />
            </a>
            <a
              className="mr2 dim"
              href="https://www.facebook.com/flowcontrolio"
            >
              <img src={IMG_FACEBOOK} />
            </a>
            <a
              className="mr2 dim"
              href="https://www.linkedin.com/company/flow-control-io"
            >
              <img src={IMG_LINKEDIN} />
            </a>
          </div>
        </div>
        */}
      </div>
    </div>
  </footer>
);

type LayoutProps = {
  children: ReactNode;
  path: string;
  title?: string;
};

const Layout = ({ children, title, path }: LayoutProps) => (
  <div className="avenir dark-gray">
    <Background />
    <SEO title={title} />

    <main className="mw9 center">
      <Header path={path} />
      {children}
    </main>

    <Footer />
  </div>
);

export default Layout;
